import React, { useRef, useState } from "react";
import Slider from "react-slick";
import decode from "jwt-decode";
import { Link, Redirect } from "react-router-dom";
import { Row, Col, Form, Input, Button, Drawer, Menu, Rate } from "antd";
import { LandingDataWrapper, MenuWrapper } from "./index.style";
import "../../theme/globalStyle";
import { Container } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import _ from "lodash";

const Landing = (props) => {
  const testimonialRef = useRef();
  const [collapsed, setCollapsed] = useState(false);

  const settings = {
    dots: false,
    slidesToShow: 3,
    className: "center",
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const settingTestimonial = {
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  const testimonialNextHandler = () => {
    testimonialRef.current.slickNext();
  };

  const menuToggle = () => {
    setCollapsed(!collapsed);
  };

  const { loading, error, data: newsData, refetch } = useQuery(
    gql`
      query {
        newsList {
          result
        }
      }
    `
  );

  const { data: testimonialData } = useQuery(
    gql`
      query {
        testiMonialList {
          result
        }
      }
    `
  );

  const newsList = _.get(newsData, "newsList.result", []);
  const testimonialList = _.get(testimonialData, "testiMonialList.result", []);

  return (
    <LandingDataWrapper>
      <div className="container-fluid g-0">
        <nav className="navbar navbar-expand-lg navbar-light">
          <a className="navbar-brand" href="#">
            <img src="/img/landing-logo.svg" alt="logo" />
          </a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={() => menuToggle()}>
            <span className="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <a className="nav-link" href="https://unitifi.com/for-professionals" target="_blank">
                  for Professionals
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://unitifi.com/faqs" target="_blank">
                  faqs
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://unitifi.com/contact" target="_blank">
                  contact
                </a>
              </li>
              <li>
                <button className="btn btn-primary uniti-signup-btn" type="button" onClick={() => window.open("https://ucit.unitifi.com/signup_new", "_blank")}>
                  Sign Up today
                </button>
              </li>
            </ul>
          </div>
        </nav>

        <Drawer bodyStyle={{ background: "rgb(255,255,255)", padding: 0 }} className="mobile-menu-nav" closable={false} onClose={menuToggle} placement="right" style={{ padding: 0 }} visible={collapsed} width={256}>
          <MenuWrapper>
            <Menu mode="inline" theme="light">
              <Menu.Item className="mobile-menu-nav-link">
                <a href={"https://unitifi.com/for-professionals"} target="_blank">
                  <span>for Professionals</span>
                </a>
              </Menu.Item>
              <Menu.Item className="mobile-menu-nav-link">
                <a href={"https://unitifi.com/faqs"} target="_blank">
                  <span>faqs</span>
                </a>
              </Menu.Item>
              <Menu.Item className="mobile-menu-nav-link">
                <a href={"https://unitifi.com/contact"} target="_blank">
                  <span>contact</span>
                </a>
              </Menu.Item>
              <Menu.Item className="mobile-menu-nav-link">
                <Button className="uniti-signup-btn" onClick={() => window.open("https://ucit.unitifi.com/signup_new", "_blank")}>
                  <span>Sign Up today</span>
                </Button>
              </Menu.Item>
              {/* <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <a className="nav-link">for professional</a>
            </li>
            <li className="nav-item">
              <a className="nav-link">faqs</a>
            </li>
            <li className="nav-item">
              <a className="nav-link">contact</a>
            </li>
            <li>
              <button className="btn btn-primary uniti-signup-btn" type="submit">
                Sign Up today
              </button>
            </li>
          </ul> */}
            </Menu>
          </MenuWrapper>
        </Drawer>

        <div className="banner-wrapper">
          <div className="bannerContent">
            <h2>Looking for a scientifcally-proven way to align with your client? You need Unitifi.</h2>
            <div className="bannerBtnWrapper">
              <Button className="uniti-joinBtn" onClick={() => window.open("https://ucit.unitifi.com/signup_new", "_blank")}>
                Sign Up today
              </Button>
            </div>
          </div>
        </div>

        <div className="companyInfoWrapper">
          <div className="companyContentWrapper">
            <Row gutter={40}>
              <Col sm={12} xl={6}>
                <div className="companyInfoBox">
                  <img src="/img/company-icon.svg" />
                  <h4>100 Companies</h4>
                  <p>Using Our Product</p>
                </div>
              </Col>
              <Col sm={12} xl={6}>
                <div className="companyInfoBox">
                  <img src="/img/practice-icon.svg" />
                  <h4>200+</h4>
                  <p>Practices</p>
                </div>
              </Col>
              <Col sm={12} xl={6}>
                <div className="companyInfoBox">
                  <img src="/img/advisor-icon.svg" />
                  <h4>1,000+</h4>
                  <p>Advisors</p>
                </div>
              </Col>
              <Col sm={12} xl={6}>
                <div className="companyInfoBox">
                  <img src="/img/client-icon.svg" />
                  <h4>5,000+</h4>
                  <p>Satisfied Clients</p>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="unitifiToolsWrapper">
          <div className="toolsContentWrapper">
            <Row gutter={12}>
              <Col sm={14} xl={10}>
                <div className="unitifiToolContent">
                  <h2>Unitifi Tools</h2>
                  <p>Immediately recognize your clients that need specific communication, and how to best market your talents through Passionate or Logical Client Marketing articles immediately reducing Marketing Spend and increasing revenue.</p>
                  <Button className="uniti-scheduleBtn" onClick={() => window.open("https://meetings.hubspot.com/todd-cutler", "_blank").focus()}>
                    Schedule A Demo Today
                  </Button>
                </div>
              </Col>
              <Col sm={10} xl={14}>
                <figure className="toolImageWrapper">
                  <img src="/img/desktop.png" />
                </figure>
              </Col>
            </Row>
          </div>
        </div>

        <div className="servicesDataWrapper">
          <div className="servicesContentWrapper">
            <div className="servicesBoxData">
              <Row gutter={48} className="serviceDataRow">
                <Col md={12} xl={8}>
                  <div className="serviceInfoBox">
                    <img src="/img/icon-stopwatch.png" />
                    <p>Minimal Time Requirement for Investor (3-7 Minutes) and Available Via Any Device</p>
                  </div>
                </Col>
                <Col md={12} xl={8}>
                  <div className="serviceInfoBox">
                    <img src="/img/icon-user.png" />
                    <p>Helps Determine Communication, Decision, and Allocation Preferences</p>
                  </div>
                </Col>
                <Col md={12} xl={8}>
                  <div className="serviceInfoBox">
                    <img src="/img/icon-screen.png" />
                    <p>Behavior Attributes Defined Based on a Statistically Validated Assessment</p>
                  </div>
                </Col>
                <Col md={12} xl={8}>
                  <div className="serviceInfoBox">
                    <img src="/img/icon-report.png" />
                    <p>White-Label Web App and Reports, Personalized Branded Assessment Invite and Client Report Features</p>
                  </div>
                </Col>
                <Col md={12} xl={8}>
                  <div className="serviceInfoBox">
                    <img src="/img/icon-household.png" />
                    <p>Dashboard Views of Assessment Results for a Complete Book of Business, Household, or Individuals</p>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="cloudServiceMainWrapper">
              <Row gutter={50}>
                <Col lg={10} xl={13}>
                  <div className="cloudServicesData">
                    <h4>COMMERCE CLOUD</h4>
                    <p>Learn about your financial behavior today</p>
                    <ul>
                      <li>Ask your financial professional if they're using the Unitifi Assessment.</li>
                      <li>Request a demo of our tools!</li>
                      <li>Demos are free and provided without any obligation!</li>
                    </ul>
                    <Button className="uniti-scheduleBtn" onClick={() => window.open("https://meetings.hubspot.com/todd-cutler", "_blank").focus()}>
                      Schedule A Demo Today
                    </Button>
                  </div>
                </Col>

                <Col lg={14} xl={11}>
                  <div className="cloudServicesBanner">
                    <img src="/img/service-banner.png" />
                  </div>
                </Col>
              </Row>
            </div>

            <div className="logoSlideWrapper">
              <div class="">
                <Slider {...settings}>
                  <div class="slide">
                    <img src="/img/slider-logo.png" />
                  </div>
                  <div class="slide">
                    <img src="/img/slider-logo.png" />
                  </div>
                  <div class="slide">
                    <img src="/img/slider-logo.png" />
                  </div>
                  <div class="slide">
                    <img src="/img/slider-logo.png" />
                  </div>
                </Slider>
              </div>
            </div>

            {newsList.length > 0 && (
              <div className="newsSectionWrapper">
                <h4>News</h4>
                <Row gutter={30}>
                  <Col xl={12}>
                    <div className="newsSectionBannerBox leftImage">
                      <img src={newsList[0].coverImage} />
                    </div>
                    <div className="newsBannerContent">
                      <h4>
                        <a href={newsList[0].url} target="_blank">
                          {newsList[0].title}
                        </a>
                      </h4>
                      <p>
                        <a href={newsList[0].url} target="_blank">
                          {newsList[0].description}
                        </a>
                      </p>
                      <span>
                        <a href={newsList[0].url} target="__blank">
                          Read More
                          <img src="/img/icon-arrow.png" className="ms-3" />
                        </a>
                      </span>
                    </div>
                  </Col>
                  {newsList.length > 1 && (
                    <Col xl={12}>
                      <Row>
                        <Col xl={24}>
                          <div className="newsSectionBannerBox rightImage mb-4">
                            <img src={newsList[1].coverImage} />
                          </div>
                          <div className="newsBannerContent rightBox">
                            <h4>
                              <a href={newsList[1].url} target="_blank">
                                {newsList[1].title}
                              </a>
                            </h4>
                            <p className="rightBoxInfo">
                              <a href={newsList[1].url} target="_blank">
                                {newsList[1].description}
                              </a>
                            </p>
                            <span>
                              <a href={newsList[1].url} target="_blank">
                                Read More
                                <img src="/img/icon-arrow.png" className="ms-3" />
                              </a>
                            </span>
                          </div>
                        </Col>
                      </Row>
                      {newsList.length > 2 && (
                        <Row>
                          <Col xl={24}>
                            <div className="newsSectionBannerBox rightImage mt-1">
                              <img src={newsList[2].coverImage} />
                            </div>
                            <div className="newsBannerContent rightBox">
                              <h4 className="rightBoxTitle">
                                <a href={newsList[2].url} target="_blank">
                                  {newsList[2].title}
                                </a>
                              </h4>
                              <span>
                                <a href={newsList[2].url} target="_blank">
                                  Read More
                                  <img src="/img/icon-arrow.png" className="ms-3" />
                                </a>
                              </span>
                            </div>
                          </Col>
                        </Row>
                      )}
                    </Col>
                  )}
                </Row>
              </div>
            )}
          </div>
        </div>

        <div className="testimonialSection">
          <div className="testimonialContent">
            <h4>TESTIMONIALS</h4>
            <Slider ref={testimonialRef} {...settingTestimonial}>
              {testimonialList.length > 0 &&
                testimonialList.map((monial) => (
                  <div className="reviewBoxWrapper" key={monial.id}>
                    <div className="reviewContent">
                      <div class="d-flex align-items-start align-items-sm-center flex-column flex-sm-row">
                        <div className="profileAvtar-wrapper">
                          <img src={monial.profileImage} />
                        </div>
                        <div className="ms-0 ms-sm-3 mt-1 mt-sm-0">
                          <h6 className="titleHeading">{monial.name}</h6>
                          <p className="companyName">{monial.company}</p>
                        </div>
                      </div>
                      <div class="rating-icon">
                        <Rate disabled defaultValue={monial.rating} />
                        {/* <img src="/img/star-icon.png" />
                    <img src="/img/star-icon.png" />
                    <img src="/img/star-icon.png" />
                    <img src="/img/star-icon.png" />
                    <img src="/img/star-icon.png" /> */}
                      </div>
                    </div>
                    <p>{monial.review}</p>
                  </div>
                ))}
            </Slider>
            {testimonialList.length > 1 && (
              <span className="d-flex justify-content-end">
                <div href="#" onClick={() => testimonialNextHandler()}>
                  <a>
                    <img src="/img/arrow-right.png" />
                  </a>
                </div>
              </span>
            )}
          </div>
        </div>

        <div className="footerLayoutWrapper">
          <div className="footer-social-links">
            <span className="socialBox">
              <a href="https://www.linkedin.com/company/unitifi/" target="_blank">
                <img src="/img/linkedin-icon.svg" />
              </a>
            </span>
            <span className="socialBox">
              <a href="https://twitter.com/unitifi_" target="_blank">
                <img src="/img/twitter-icon.svg" />
              </a>
            </span>
            <span className="socialBox">
              <a href="https://www.facebook.com/unitifi" target="_blank">
                <img src="/img/facebook-icon.svg" />
              </a>
            </span>
            <span className="socialBox">
              <a href="mailto:info@unitifi.com">
                <img src="/img/envelope-icon.svg" />
              </a>
            </span>
            <span className="socialBox">
              <a href="https://youtube.com/@unitifi" target="_blank">
                <img src="/img/youtube-icon.svg" />
              </a>
            </span>
          </div>

          <div className="footer-quicklinks">
            <ul className="list-unstyled">
              <li>
                <a href="https://meetings.hubspot.com/todd-cutler" target="_blank">
                  Schedule a meeting
                </a>
              </li>
              <li>
                <a href="https://ucit.unitifi.com/signup_new" target="_blank">
                  sign up today!
                </a>
              </li>
              <li>
                <a href="https://ucit.unitifi.com/login" target="_blank">
                  Member Login
                </a>
              </li>
              <li>
                <a href="https://unitifi.com/terms" target="_blank">
                  terms of use
                </a>
              </li>
              <li>
                <a href="https://unitifi.com/contact" target="_blank">
                  contact
                </a>
              </li>
            </ul>
          </div>

          <div className="footer-quicklinks contactInfo">
            <ul className="list-unstyled">
              <li>Unitifi, 12020 shamrock plaza #200, omaha, ne 68154, usa </li>
              <li>402-979-7185</li>
            </ul>
            <span>&#169;2023 Unitifi | All right reserved</span>
          </div>
        </div>
      </div>
    </LandingDataWrapper>
  );
};

export default Landing;
