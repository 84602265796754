import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Landing from "./containers/landing";

const PublicRoutes = (props) => {
  return (
    <BrowserRouter>
      <Switch>
        <Route component={Landing} exact path="/" />
      </Switch>
    </BrowserRouter>
  );
};

export default PublicRoutes;
