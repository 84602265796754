import styled from "styled-components";
import { palette } from "styled-theme";

export const LandingDataWrapper = styled.div`
  .navbar {
    background: #fff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      align-items: center;
      li {
        padding: 0 25px;
        a {
          color: #657a85;
          font-size: 14px;
          font-weight: 500;
          line-height: 46px;
          text-transform: uppercase;
        }
        .btn {
          &.uniti-signup-btn {
            min-width: 178px;
            height: 52px;
            border-radius: 50px;
            text-transform: uppercase;
            border: 1px solid #657a85;
            font-size: 14px;
            font-weight: 500;
            background: transparent !important;
            color: #657a85 !important;
          }
        }
      }
    }
  }

  .mobile-menu-nav {
    .ant-drawer-content-wrapper {
      .ant-drawer-content {
        .ant-drawer-wrapper-body {
          padding: 20px;
          .ant-drawer-body {
            .ant-menu {
              .ant-menu-item {
                .mobile-menu-nav-link {
                  a {
                    text-decoration: none;
                    span {
                      font-family: "Avenir" !important;
                      font-size: 14px;
                      font-weight: 500;
                      line-height: 46px;
                      text-transform: uppercase;
                      color: #657a85;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .banner-wrapper {
    background: url("/img/banner-landing.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    width:100%;
    height: 550px;
    position:relative;
    &::before{
      position:absolute;
      content:'';
      background:rgba(0,0,0,0.6);
      width:100%;
      height:100%;
    }
    .bannerContent {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      padding-left: 100px;
      position:absolute;
      z-index:2;
      @media only screen and (max-width: 767px) {
        padding-left: 50px;
      }
      @media only screen and (max-width: 575px) {
        padding-left: 25px;
      }
      @media only screen and (max-width: 480px) {
        padding-left: 12px;
      }
      h2 {
        max-width: 775px;
        width: 100%;
        font-size: 36px;
        font-weight: 600;
        line-height: 60px;
        color: #fff;
        margin-bottom: 1.5rem;
        @media only screen and (max-width: 480px) {
          font-size: 24px;
          line-height: 40px;
        }
      }
      .bannerBtnWrapper {
        .ant-btn {
          &.uniti-joinBtn {
            min-width: 177px;
            width: 100%;
            height: 60px;
            border-radius: 50px;
            background: #0358cf;
            border-color: #0358cf;
            color: #fff;
            font-size: 18px;
            font-weight: 700;
            text-transform: capitalize;
          }
        }
      }
    }
  }

  .companyInfoWrapper {
    background: #fff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    padding: 80px;
    @media only screen and (max-width: 767px) {
      padding: 45px;
    }
    .companyContentWrapper {
      max-width: 1300px;
      width: 100%;
      margin: 0 auto;
      @media only screen and (max-width: 1199px) {
        max-width: 600px;
      }
      @media only screen and (max-width: 575px) {
        max-width: 270px;
      }
      .companyInfoBox {
        background: rgba(255, 255, 255, 0.7);
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        max-width: 278px;
        width: 100%;
        height: 190px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 23px;
        @media only screen and (max-width: 1199px) {
          margin-bottom: 1rem;
        }
        h4 {
          font-size: 26px;
          font-weight: 900;
          line-height: 40px;
          color: #1a293f;
          margin-bottom: 0;
        }
        p {
          font-size: 18px;
          font-weight: 700;
          line-height: 45px;
          color: #1a293f;
          margin-bottom: 0;
        }
      }
    }
  }

  .unitifiToolsWrapper {
    background: #fff;
    box-shadow: 0px 26.7864px 117.19px rgba(0, 111, 186, 0.06);
    height: 475px;
    padding: 0 120px;
    position: relative;
    @media only screen and (max-width: 1199px) {
      padding: 0 45px;
    }
    .toolsContentWrapper {
      max-width: 1250px;
      width: 100%;
      margin: 0 auto;
      .unitifiToolContent {
        padding: 50px 0;
        @media only screen and (max-width: 1199px) {
          padding: 20px 0;
        }
        h2 {
          font-size: 26px;
          font-weight: 600;
          line-height: 40px;
          color: #181818;
          margin-bottom: 18px;
        }
        p {
          max-width: 384px;
          width: 100%;
          font-size: 18px;
          line-height: 40px;
          color: #181818;
          margin-bottom: 40px;
        }

        .ant-btn {
          &.uniti-scheduleBtn {
            max-width: 253px;
            width: 100%;
            border-radius: 50px;
            background: #0358cf;
            border-color: #0358cf;
            height: 60px;
            font-size: 18px;
            font-weight: 700;
            color: #fff;
          }
        }
      }
      .toolImageWrapper {
        position: absolute;
        top: -75px;
        @media only screen and (max-width: 1199px) {
          top: 75px;
        }
        @media only screen and (max-width: 575px) {
          display: none;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .servicesDataWrapper {
    background: #fff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    padding: 80px 110px;
    @media only screen and (max-width: 991px) {
      padding: 50px 80px;
    }
    @media only screen and (max-width: 575px) {
      padding: 30px 20px;
    }
    .servicesContentWrapper {
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;
      @media only screen and (max-width: 991px) {
        max-width: 1200px;
      }
      .servicesBoxData {
        @media only screen and (max-width: 767px) {
          max-width: 370px;
          width: 100%;
          margin: auto;
        }
        .serviceDataRow{
          display:flex;
          flex-wrap:wrap;
          justify-content: center;
        }
        .serviceInfoBox {
          padding: 40px;
          background: #fff;
          box-shadow: 0px 26.7864px 117.19px rgba(0, 111, 186, 0.06);
          border-radius: 35px;
          max-width: 375px;
          width: 100%;
          height: 344px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          margin-bottom:1.25rem;
          @media only screen and (max-width: 1199px) {
            margin-bottom: 1.20rem;
          }
          img {
            //width: 100%;
            //height: 100%;
            object-fit: contain;
          }
          p {
            max-width: 310px;
            width: 100%;
            text-align: center;
            font-size: 19px;
            line-height: 30px;
            color: #181818;
            margin-top: 16px;
          }
        }
      }

      .cloudServiceMainWrapper {
        padding-top: 85px;
        @media only screen and (max-width: 767px) {
          padding-top: 40px;
        }
        .cloudServicesData {
          padding-top: 30px;
          max-width: 677px;
          width: 100%;
          h4 {
            font-size: 22px;
            font-weight: 600;
            line-height: 44px;
            color: #000;
            margin-bottom: 0;
          }
          p {
            font-size: 30px;
            font-weight: 700;
            line-height: 44px;
            color: #000;
          }
          ul {
            list-style: none;
            li {
              font-size: 18px;
              font-weight: 400;
              line-height: 44px;
              color: #000;
              position: relative;
              &::before {
                position: absolute;
                content: "";
                background-image: url("/img/icon-true.svg");
                background-repeat: no-repeat;
                width: 19px;
                height: 15px;
                left: -30px;
                top: 15px;
              }
            }
          }
          .ant-btn {
            &.uniti-scheduleBtn {
              max-width: 255px;
              width: 100%;
              border-radius: 50px;
              background: #0358cf;
              border-color: #0358cf;
              height: 60px;
              font-size: 18px;
              font-weight: 700;
              color: #fff;
            }
          }
        }

        .cloudServicesBanner {
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }

      .logoSlideWrapper {
        padding: 80px 0;
        @media only screen and (max-width: 575px) {
            max-width:220px;
            width:100%;
            margin:auto;
        }
      }

      .newsSectionWrapper {
        padding: 80px 0;
        @media only screen and (max-width: 575px) {
          padding:10px 0;
        }
        h4 {
          font-size: 22px;
          font-weight: 600;
          line-height: 44px;
          color: #181818;
          text-transform: uppercase;
        }
        .newsSectionBannerBox {
          border-radius: 10px;
          overflow: hidden;
          position: relative;
          &.leftImage{
            height:715px;
            @media only screen and (max-width: 1199px) {
              height:auto;
            }
            img{
              width:100%;
              height:100%;
              object-fit:contain;
            }
          }
          &.rightImage{
            max-width:604px;
            width:100%;
            height:342px;
            @media only screen and (max-width: 1199px) {
              max-width:100%;
              height:auto;
            }
            img{
              width:100%;
              height:100%;
              object-fit:contain;
            }
          }
          @media only screen and (max-width: 1199px) {
            margin-bottom: 1.5rem;
          }
          /* img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            &.leftImage {
              height: 689px;
              @media only screen and (max-width: 1199px) {
                height: 600px;
              }
              @media only screen and (max-width: 480px) {
                height: 400px;
              }
            }
          } */
          &::before {
            position: absolute;
            content: "";
            background: #121212;
            opacity: 0.6;
            width: 100%;
            height: 100%;
          }
        }
        .newsBannerContent {
          position: absolute;
          bottom: 0;
          padding: 25px 43px;
          @media only screen and (max-width: 575px) {
            padding: 25px 18px;
          }
          &.rightBox {
            padding: 40px 45px;
            @media only screen and (max-width: 575px) {
              padding: 10px 20px;
            }
          }
          h4 {
            a{
            font-size: 24px;
            font-weight: 700;
            line-height: 40px;
            color: #fff;
            max-width: 413px;
            width: 100%;
            margin-bottom: 10px;
            text-decoration:none;
            } 
            @media only screen and (max-width: 575px) {
              font-size: 16px;
              line-height: 25px;
            }
            &.rightBoxTitle {
              max-width: 545px;
              width: 100%;
            }
          }
          p {
              a{
                font-size: 18px;
                font-weight: 600;
                line-height: 30px;
                color: #fff;
                max-width: 384px;
                width: 100%;
                text-decoration:none;
              }
            @media only screen and (max-width: 575px) {
              font-size: 14px;
              line-height: 20px;
            }
            &.rightBoxInfo {
              max-width: 517px;
              width: 100%;
            }
          }
          span {
              a{
                font-size: 18px;
                font-weight: 700;
                line-height: 32px;
                color: #fff;
                text-decoration:none;
              }
            @media only screen and (max-width: 575px) {
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }

  .testimonialSection {
    background: #fff;
    //box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    padding-bottom: 80px;
    overflow: hidden;
    .testimonialContent {
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;
      padding: 20px;
      h4 {
        font-size: 22px;
        font-weight: 600;
        line-height: 44px;
        color: #181818;
        text-transform: uppercase;
        margin-bottom: 14px;
      }
      .slick-slider {
        .slick-list {
          padding: 0 !important;
          overflow: visible;
          .slick-track {
            .slick-slide {
              .reviewBoxWrapper {
                background: #ffffff;
                box-shadow: 0px 26.7864px 117.19px rgba(0, 111, 186, 0.06);
                padding: 40px;
                max-width: 937px;
                width: 100%;
                height: auto;
                margin-bottom: 45px;
                @media only screen and (max-width: 767px) {
                  padding: 25px;
                  margin-bottom: 30px;
                }
                .reviewContent {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-bottom: 1.5rem;
                  @media only screen and (max-width: 480px) {
                    justify-content: space-around;
                  }
                  .profileAvtar-wrapper{
                    width:65px;
                    height:65px;
                    img{
                      width:100%;
                      height:100%;
                      border-radius:50px;
                    }
                  }
                  .titleHeading{
                    font-size:24px;
                    font-weight:600;
                    line-height:19px;
                    color:#181818;
                    margin-bottom:5px;
                    @media only screen and (max-width: 575px) {
                      font-size:20px;
                      line-height:15px;
                    }
                    @media only screen and (max-width: 480px) {
                      font-size:16px;
                      line-height:15px;
                    }
                  }
                  .companyName{
                    font-size:22px;
                    line-height:22px;
                    color:#7b7b7b;
                    margin-bottom:0;
                    @media only screen and (max-width: 575px) {
                      font-size:18px;
                      line-height:18px;
                    }
                    @media only screen and (max-width: 480px) {
                      font-size:16px;
                      line-height:15px;
                    }
                  }
                  .rating-icon {
                    .ant-rate{
                      .ant-rate-star{
                        &.ant-rate-star-full{
                          svg{
                            fill:#FF9500;
                          }  
                        }
                      }
                    }
                   }
                  p {
                    font-size: 20px;
                    line-height: 22px;
                    color:#181818;
                    margin-bottom:0;
                    @media only screen and (max-width: 767px) {
                      font-size: 15px;
                      line-height: 18px;
                    }
                  }
                }
                p {
                  max-width: 850px;
                  width: 100%;
                  font-size: 20px;
                  line-height: 28px;
                  color: #181818;
                  @media only screen and (max-width: 767px) {
                    font-size: 18px;
                    line-height: 24px;
                  }
                  @media only screen and (max-width: 575px) {
                    font-size: 14px;
                    line-height: 22px;
                  }
                }
              }
              img {
                display: unset;
              }
            }
          }
        }
      }
    }
  }

  .footerLayoutWrapper {
    background: #6a7886;
    height: auto;
    padding: 70px;
    .footer-quicklinks {
      max-width: 725px;
      width: 100%;
      margin: auto;
      &.contactInfo {
        max-width: 565px;
        width: 100%;
        margin: auto;
      }
      ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        @media only screen and (max-width: 575px) {
          flex-direction: column;
        }
        li {
          font-family: "Avenir";
          font-size: 14px;
          font-weight: 500;
          line-height: 46px;
          color: #fff;
          text-transform: uppercase;
          a{
            font-family: "Avenir";
            font-size: 14px;
            font-weight: 500;
            line-height: 46px;
            color: #fff;
            text-transform: uppercase;
            display: inline-block;
            text-decoration: none !important;
          }
        }
      }
      span {
        display: block;
        font-family: "Avenir";
        font-size: 14px;
        font-weight: 500;
        line-height: 46px;
        text-align: center;
        color: #9aa4ad;
      }
    }
    .footer-social-links {
      max-width: 230px;
      width: 100%;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 20px;
      .socialBox {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        background: #fff;
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
`;

export const MenuWrapper = styled.div`
  .menuItem {
    border-color: transparent;
    height:50px;
    line-height:50px;
  }
  .ant-menu-item-selected > .menuItem {
    background: #f2f3f5 !important;
  }
  .mobile-menu-nav-link 
                    a {
                      text-decoration:none;
                      span {
                        font-family: "Avenir" !important;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 46px;
                        text-transform: uppercase;
                        color: #657a85;
                        text-decoration:none;
                      }
                    }
  .ant-btn {
            &.uniti-signup-btn {
              min-width: 150px;
              height: 36px;
              border-radius: 50px;
              text-transform: uppercase;
              border: 1px solid #657a85;
              font-size: 14px;
              font-weight: 500;
              background: transparent !important;
              color: #657a85 !important;
            }
          }
        
`;
